import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { toast } from "react-toastify";
import { CustomerRequest, CustomerResponse } from "../types/index";

const getCustomers = async (): Promise<CustomerResponse[]> => {
  const { data } = await axios.get(`${BASE_URL}/customers`);
  return data.data;
};

const updateCustomer = async (values: CustomerResponse): Promise<CustomerResponse> => {
  const { data } = await axios.put(`${BASE_URL}/customers/${values.id}`, values).catch((error) => Promise.reject(error));
  return data.data;
};

const addCustomer = async (values: CustomerRequest): Promise<CustomerResponse> => {
  const { data } = await axios.post(`${BASE_URL}/customers`, values);
  return data.data;
};

// -- UseQuery -- //

const customersQueryKey = "customers";

export const useFetchCustomers = () => {
  return useQuery<CustomerResponse[]>({ queryKey: [customersQueryKey], queryFn: () => getCustomers() });
};

export const useAddCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newCustomer: CustomerRequest): Promise<CustomerRequest> => await addCustomer(newCustomer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [customersQueryKey] });
      toast.success("Added Customer successfully!");
    },
  });
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedCustomer: CustomerResponse) => updateCustomer(updatedCustomer),
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: [customersQueryKey] });
      toast.success("Customer updated!");
    },
  });
};
