import { useEffect, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Container, Divider, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { CloudDownload, PictureAsPdf, CheckCircleOutline, AddCircleOutline, MoreVert, Delete, ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import MassBalanceRowTable from "../../components/tables/MassbalanceRowTable";
import SubstrateListTable from "../../components/tables/SubstrateListTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useAddIncomingPos, useDeleteMassbalance, useFetchMassbalanceById, useFetchSubstrateList } from "../../hooks/massbalance";
import { useFetchIncomingPos } from "../../hooks/incomingPos";
import { toast } from "react-toastify";
import { DateFormatter, FRONTEND_MONTH_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, FRONTEND_PERCENT_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { onDownloadFile } from "../../utils/DownloadFiles";
import { useAuth } from "../../hooks/useAuth";
import { ADMIN_ROLE } from "../../services/base";
import { MassBalanceResponse } from "../../types";

const MassbalanceDetails = () => {
  const [incomingPosId, setIncomingPosId] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: massBalance, isLoading, isError, isSuccess } = useFetchMassbalanceById(id!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const deleteMassBalance = useDeleteMassbalance(id!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: substrateList } = useFetchSubstrateList(id!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const addIncomingPos = useAddIncomingPos(id!);
  const { data: incomingPosList, isLoading: isPosListLoading } = useFetchIncomingPos();

  //Temp solution until Backend can send a confirmation that incoming pos is already added.
  useEffect(() => {
    if (!isLoading && !isPosListLoading) {
      const incomingPos = incomingPosList && incomingPosList.find((item) => item.massBalanceId === id!);
      if (incomingPos) {
        setIncomingPosId(incomingPos.id);
      }
    }
  }, [isLoading, isPosListLoading, incomingPosList, id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  const handleAddIncomingPos = (subsidy: boolean) => {
    addIncomingPos.mutate(subsidy, {
      onSuccess: () => {
        toast.success("Incoming PoS created!");
        setIncomingPosId("");
        queryClient.invalidateQueries({ queryKey: ["pos"] });
        navigate("/incoming");
      },
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMassbalance = () => {
    deleteMassBalance.mutate(this, {
      onSuccess: () => {
        toast.success("Massbalance deleted successfully");
        setIncomingPosId("");
        queryClient.invalidateQueries({ queryKey: ["massbalances"] });
        navigate("/massbalances");
      },
    });
  };

  const getMenuItems = (massBalance:MassBalanceResponse): React.JSX.Element[] => {
    const downloadMassbalance = [
      <MenuItem key="download-massbalance-xlsx" onClick={() => {
        onDownloadFile({ format: "xlsx", id: massBalance.id, fileName: massBalance.name, dataType: "massbalances" });
        handleClose();
      }}>
        <CloudDownload sx={{ mr: 1 }} color="primary" />
        Download as Excel
      </MenuItem>,
      <MenuItem key="download-massbalance-pdf" onClick={() => {
        onDownloadFile({ format: "pdf", id: massBalance.id, fileName: massBalance.name, dataType: "massbalances" });
        handleClose();
      }}>
        <PictureAsPdf sx={{ mr: 1 }} color="primary" />
        Download as PDF
      </MenuItem>,
    ];

    const crudMassbalance = [
      <MenuItem key="create-pos-subsidy">
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 0, m: 0 }}>
          <IconButton
            aria-label="Create PoS with subsidy"
            color="primary"
            onClick={() => {
              handleAddIncomingPos(true), handleClose();
            }}
            sx={{ p: 0, m: 0 }}>
            <AddCircleOutline />
            <Typography sx={{ ml: 1, color: theme.palette.text.primary }}>Create PoS with subsidy</Typography>
          </IconButton>
        </Box>
      </MenuItem>,
      <MenuItem key="create-pos-without-subsidy">
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 0, m: 0 }}>
          <IconButton
            aria-label="Create PoS without subsidy"
            color="primary"
            onClick={() => {
              handleAddIncomingPos(false), handleClose();
            }}
            sx={{ p: 0, m: 0 }}>
            <AddCircleOutline />
            <Typography sx={{ ml: 1, color: theme.palette.text.primary }}>Create PoS without subsidy</Typography>
          </IconButton>
        </Box>
      </MenuItem>,
      <MenuItem key="delete-massbalance">
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 0, m: 0 }}>
          <IconButton
            aria-label="Delete Massbalance"
            color="primary"
            onClick={() => {
              handleDeleteMassbalance(), handleClose();
            }}
            sx={{ p: 0, m: 0 }}>
            <Delete />
            <Typography sx={{ ml: 1, color: theme.palette.text.primary }}>Delete Massbalance</Typography>
          </IconButton>
        </Box>
      </MenuItem>,
    ];

    const hasPos = [
      <MenuItem key="go-to-pos" onClick={() => navigate(`/incoming`)}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CheckCircleOutline color="primary" />
          <Typography>Go to PoS</Typography>
        </Box>
      </MenuItem>,
    ];

    if (incomingPosId) {
      return [...hasPos, ...downloadMassbalance];
    }

    return user && user.role === ADMIN_ROLE ? [...crudMassbalance, ...downloadMassbalance] : [...downloadMassbalance];
  };

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Button variant="text" onClick={() => navigate("/massbalances")}>
          <ArrowBack />
        </Button>
        <Typography variant="h4" color="primary" sx={{ flexGrow: 1 }}>
          Massbalance details
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {isSuccess && massBalance && (
        <Box sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}></Typography>
            <Box>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls={open ? "more-action-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleMenu}
                color="primary">
                <MoreVert />
              </IconButton>
              <Menu id="more-action-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {getMenuItems(massBalance)}
              </Menu>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Box>
              <Typography variant="caption">Plant</Typography>
              <Divider sx={{ bgcolor: theme.palette.primary.main }} />
              <Typography variant="h6">{massBalance.plantName}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">Production Period</Typography>
              <Divider sx={{ bgcolor: theme.palette.primary.main }} />
              <Typography variant="h6">{DateFormatter(massBalance.produced, FRONTEND_MONTH_FORMAT)}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 3 }}>
            <Box>
              <Typography variant="caption">Theoretical Production</Typography>
              <Divider sx={{ bgcolor: theme.palette.primary.main }} />
              <Typography variant="h6">
                {NumberFormatter(massBalance.theoreticalNetProductionKWh / 1000, FRONTEND_NUMBER_FORMAT, 0)} MWh
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">Actual Production</Typography>
              <Divider sx={{ bgcolor: theme.palette.primary.main }} />
              <Typography variant="h6">
                {NumberFormatter(massBalance.actualNetProductionKWh / 1000, FRONTEND_NUMBER_FORMAT, 0)} MWh
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">Correction factor</Typography>
              <Divider sx={{ bgcolor: theme.palette.primary.main }} />
              <Typography variant="h6">{NumberFormatter(massBalance.correctionFactor, FRONTEND_PERCENT_FORMAT, 2)}%</Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, overflowX: "auto" }}>{massBalance && <MassBalanceRowTable data={massBalance} />}</Box>
          <Box sx={{ mt: 3, mb: 2, overflowX: "auto" }}>{substrateList && <SubstrateListTable data={substrateList} />}</Box>
        </Box>
      )}
    </Container>
  );
};

export default MassbalanceDetails;
