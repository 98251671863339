import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { DataResponse, SupplierRequest, SupplierResponse } from "../types";

export const useFetchSuppliers = (plantId: string) => {
  return useQuery({
    queryKey: ["suppliers"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SupplierResponse[]>>(`${BASE_URL}/suppliers/${plantId}`);
      data.data.sort((s1, s2) => s1.owner.localeCompare(s2.owner));
      return data.data;
    },
  });
};

export const useFetchActiveSuppliers = (plantId: string) => {
  return useQuery({
    queryKey: ["activeSuppliers"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SupplierResponse[]>>(`${BASE_URL}/suppliers/${plantId}/activated`);
      data.data.sort((s1, s2) => s1.owner.localeCompare(s2.owner));
      return data.data;
    },
  });
};

export const useFetchNonActiveSuppliers = (plantId: string) => {
  return useQuery({
    queryKey: ["nonActivesuppliers"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SupplierResponse[]>>(`${BASE_URL}/suppliers/${plantId}/deactivated`);
      data.data.sort((s1, s2) => s1.owner.localeCompare(s2.owner));
      return data.data;
    },
  });
};

export const useAddNewSupplier = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["suppliers"],
    mutationFn: async (values: SupplierRequest) => {
      const { data, status } = await axios.post(`${BASE_URL}/suppliers/${plantId}`, values);
      if (status) {
        queryClient.invalidateQueries({
          queryKey: ["suppliers"],
        });
        queryClient.invalidateQueries({
          queryKey: ["nonActivesuppliers"],
        });
        queryClient.invalidateQueries({
          queryKey: ["activeSuppliers"],
        });
        return data.data;
      } else {
        return false;
      }
    },
  });
};

export const useUpdateSupplier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["suppliers"],
    mutationFn: async ({ values, supplierId }: { values: SupplierRequest; supplierId: string }) => {
      const { data } = await axios.put(`${BASE_URL}/suppliers/${supplierId}`, values);
      queryClient.invalidateQueries({
        queryKey: ["suppliers"],
      });
      queryClient.invalidateQueries({
        queryKey: ["nonActivesuppliers"],
      });
      queryClient.invalidateQueries({
        queryKey: ["activeSuppliers"],
      });
      return data.data;
    },
  });
};
