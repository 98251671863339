import { useParams, useNavigate } from "react-router-dom";
import { useAddToStorage, useFetchPosById } from "../../hooks/incomingPos";
import LoadingSpinner from "../../components/LoadingSpinner";
import { AddCircleOutline, ArrowBack, CheckCircleOutline, CloudDownload, MoreVert, PictureAsPdf } from "@mui/icons-material";
import { Container, Box, Typography, Button, Divider, useTheme, IconButton, Menu, MenuItem } from "@mui/material";
import { DateFormatter, FRONTEND_DATE_FORMAT, FRONTEND_MONTH_FORMAT } from "../../utils/DateFormatter";
import { getTotalSumInKWh } from "../../utils/Calculations";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import IncomingPosRowTable from "../../components/tables/IncomingPosRowTable";
import { onDownloadFile } from "../../utils/DownloadFiles";
import { useEffect, useState } from "react";
import { ADMIN_ROLE } from "../../services/base";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { IncomingPosDataResponse } from "../../types";
import { useAuth } from "../../hooks/useAuth";

const PosDetails = () => {
  const { id } = useParams();
  const { data: posData, isLoading } = useFetchPosById(id!);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [certifiedPosData, setCertifiedPosData] = useState<IncomingPosDataResponse[]>([]);
  const [unCertifiedPosData, setUncertifiedPosData] = useState<IncomingPosDataResponse[]>([]);
  const addPosToStorage = useAddToStorage();
  const queryClient = useQueryClient();
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();

  useEffect(() => {
    if (posData && posData.incomingPosData.some((pos) => pos.selfDeclaration === 0)) {
      setUncertifiedPosData(posData.incomingPosData.filter((pos) => pos.selfDeclaration === 0));
    }

    if (posData && posData.incomingPosData.some((pos) => pos.selfDeclaration !== 0)) {
      setCertifiedPosData(posData.incomingPosData.filter((pos) => pos.selfDeclaration !== 0));
    }
  }, [posData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddToStorage = (id: string) => {
    addPosToStorage.mutate(id, {
      onSuccess: () => {
        toast.success("Added Pos to storage!");
        queryClient.invalidateQueries({ queryKey: ["pos"] });
        queryClient.invalidateQueries({ queryKey: ["bathtub"] });
        handleClose();
        navigate("/storage");
      },
    });
  };


  const getCo2EquivalentType = (typeValue: number): string => {
    if (typeValue === 0) {
      return "None";
    }
    return typeValue === 1 ? "DDV" : "TDV";
  };

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Button variant="text" onClick={() => navigate("/incoming")}>
          <ArrowBack />
        </Button>
        <Typography variant="h4" color="primary" sx={{ flexGrow: 1 }}>
          PoS details
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {posData && (
        <>
          <Box sx={{ my: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "right" }}>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls={isMenuOpen ? "more-action-menu" : undefined}
                aria-expanded={isMenuOpen ? "true" : undefined}
                onClick={handleClickMenu}
                color="primary">
                <MoreVert />
              </IconButton>
              <Menu id="more-action-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
                {user?.role === ADMIN_ROLE && (
                  <MenuItem disabled={posData.inBathtub} onClick={() => handleAddToStorage(posData.id)}>
                    {posData.inBathtub ? (
                      <>
                        <CheckCircleOutline sx={{ mr: 1 }} color="primary" />
                        Already added to storage
                      </>
                    ) : (
                      <>
                        <AddCircleOutline sx={{ mr: 1 }} color="primary" />
                        Add PoS to Storage
                      </>
                    )}
                  </MenuItem>
                )}
                <MenuItem onClick={() => {
                    onDownloadFile({ format: "xlsx",
                      dataType: "incomingpos",
                      id: posData.id,
                      fileName: posData.uniqueNumber,
                    });
                    handleClose();
                }}>
                  <CloudDownload sx={{ mr: 1 }} color="primary" />
                  Download as Excel
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDownloadFile({
                      format: "pdf",
                      dataType: "incomingpos",
                      id: posData.id,
                      fileName: posData.uniqueNumber,
                    });
                    handleClose();
                  }}>
                  <PictureAsPdf sx={{ mr: 1 }} color="primary" />
                  Download as PDF
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
              <Box>
                <Typography variant="caption">PoS Number</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{posData.uniqueNumber}</Typography>
              </Box>

              <Box>
                <Typography variant="caption">Type</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{getCo2EquivalentType(posData.co2EquivalentType) ?? "N/A"}</Typography>
              </Box>

              <Box>
                <Typography variant="caption">Plant</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{posData.plantName}</Typography>
              </Box>

              <Box>
                <Typography variant="caption">Date of issuance</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{DateFormatter(posData.issueDate, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
              <Box>
                <Typography variant="caption">Total amount</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">
                  {`${NumberFormatter(getTotalSumInKWh(posData.incomingPosData), FRONTEND_NUMBER_FORMAT)} MWh`}
                </Typography>
              </Box>

              <Box>
                <Typography variant="caption">Period Start</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{DateFormatter(posData.productionPeriodStart, FRONTEND_MONTH_FORMAT)}</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Period End</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6">{DateFormatter(posData.productionPeriodEnd, FRONTEND_MONTH_FORMAT)}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />

          {certifiedPosData.length > 0 && (
            <Box sx={{ marginBottom: 5 }}>
              <Typography variant="h6">Certified</Typography>
              <IncomingPosRowTable incomingPosData={certifiedPosData} />
            </Box>
          )}

          {unCertifiedPosData.length > 0 && (
            <Box sx={{ marginBottom: 5 }}>
              <Typography variant="h6">Uncertified</Typography>
              <IncomingPosRowTable incomingPosData={unCertifiedPosData} />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default PosDetails;
