import { Box, Typography } from "@mui/material";
import { DataGrid, GridValueSetterParams } from "@mui/x-data-grid";
import { useUpdateBioMass } from "../../hooks/biomass";
import { defaultTableStyle } from "../themes/tableStyle";
import { BioMassRequest, BioMassResponse } from "../../types";
import { toast } from "react-toastify";

type BioMassPotentialTableProps = {
  data: BioMassResponse[];
};

const BioMassPotentialTable = ({ data }: BioMassPotentialTableProps) => {
  const updateBioMass = useUpdateBioMass();

  const saveBioMass = (uppdatedBioMass: BioMassResponse) => {
    const { id, ...bioMass } = uppdatedBioMass;

    const bioMassData: BioMassRequest = {
      ...bioMass,
      category: bioMassCategory(bioMass.category),
    };

    updateBioMass.mutate(
      { id, bioMassData },
      {
        onSuccess: () => {
          toast.success("Biomass successfully updated!");
        },
      },
    );
  };

  const bioMassCategory = (category: string) => {
    switch (category) {
      case "Manure":
        return 0;
      case "Biowaste":
        return 1;
      case "Crops":
        return 2;
      default:
        return NaN;
    }
  };

  return (
    <Box>
      {data && data.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography>No data is available.</Typography>
        </Box>
      ) : (
        <Box sx={{ height: 750 }}>
          <DataGrid
            rows={data}
            columns={[
              {
                field: "name",
                headerName: "Biomass",
                flex: 1,
                type: "string",
                editable: true,
                valueSetter: (params: GridValueSetterParams) => {
                  if (params.row.name != params.value) {
                    params.row.name = params.value;
                    saveBioMass(params.row);
                  }
                  return params.row;
                },
                renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
              },
              {
                field: "category",
                headerName: "Category",
                flex: 1,
                type: "singleSelect",
                valueOptions: ["Biowaste", "Manure", "Crops"],
                editable: true,
                valueSetter: (params: GridValueSetterParams) => {
                  console.log(params);
                  console.log(Number.isNaN(bioMassCategory(params.value)));
                  if (Number.isNaN(bioMassCategory(params.value))) return params.row;
                  if (params.row.category !== params.value) {
                    params.row.category = params.value;
                    saveBioMass(params.row);
                  }
                  return params.row;
                },
                renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
              },
              {
                field: "defaultPotential",
                headerName: "Default Biomethane Potential",
                flex: 1,
                type: "number",
                editable: true,
                valueSetter: (params: GridValueSetterParams) => {
                  if (params.row.defaultPotential != params.value) {
                    params.row.defaultPotential = params.value;
                    saveBioMass(params.row);
                  }
                  return params.row;
                },
                renderCell: (params) => (
                  <Typography sx={{ pr: 3 }} variant="caption" color={params.value == 0 ? "error" : ""}>
                    {params.value}
                  </Typography>
                ),
              },
            ]}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
            density="compact"
            isRowSelectable={() => false}
            hideFooterSelectedRowCount
            sx={defaultTableStyle}
          />
        </Box>
      )}
    </Box>
  );
};

export default BioMassPotentialTable;
