import { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, Tab, Tabs, useTheme, Divider } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SwitchModeButton } from "./SwitchModeButton";
import { useAuth } from "../hooks/useAuth";
import { useFetchBioMasses } from "../hooks/biomass";
import { useFetchCustomers } from "../hooks/customer";
import { useFetchPlants } from "../hooks/plant";
import { CustomerResponse, PlantResponse } from "../types";

const PlantOwnerNavbar = () => {
  const theme = useTheme();
  const [[currentRootPath]] = location.pathname.matchAll(/^(\/[^/]*)/g);

  return (
    <Tabs value={currentRootPath}>
      <Tab
        label="Daily Production"
        value="/daily-production"
        to="/daily-production"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab label="Feedstocks" value="/feedstocks" to="/feedstocks" component={Link} sx={{ color: theme.palette.text.primary }} />
      <Tab
        label="Suppliers List"
        value="/plantsuppliers"
        to="/plantsuppliers"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab
        label="Monthly summary"
        value="/monthly-summary"
        to="/monthly-summary"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab label="Massbalances" value="/massbalances" to="/massbalances" component={Link} sx={{ color: theme.palette.text.primary }} />
      <Tab label="Produced PoS" value="/incoming" to="/incoming" component={Link} sx={{ color: theme.palette.text.primary }} />
    </Tabs>
  );
};

const Navbar = () => {
  const { user, onLogout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [[currentRootPath]] = location.pathname.matchAll(/^(\/[^/]*)/g);
  const theme = useTheme();

  const { data: biomasses } = useFetchBioMasses(false);
  const { data: customers } = useFetchCustomers();
  const { data: plants } = useFetchPlants();

  const biomasses_alert = biomasses ? !biomasses.every((bm) => bm.defaultPotential != 0 && bm.category != "Uncategorized") : false;
  const plants_alert = plants
    ? !plants.every((p) => {
        const keys = Object.keys(p) as Array<keyof PlantResponse>;
        for (let i = 0; i < keys.length; i++) {
          const val = p[keys[i]];
          if (val == "null" || val == "unknown") {
            return false;
          }
        }
        return true;
      })
    : false;

  const customers_alert = customers
    ? !customers.every((c) => {
        const keys = Object.keys(c) as Array<keyof CustomerResponse>;
        for (let i = 0; i < keys.length; i++) {
          const val = c[keys[i]];
          if (val == "null" || val == "unknown") {
            return false;
          }
        }
        return true;
      })
    : false;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    setAnchorEl(null);
  };

  const handleDarkModeSwitch = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ background: theme.palette.primary.main, color: theme.palette.text.secondary }}
        sx={{ background: "linear-gradient(90deg, rgba(47,112,14,1) 0%, rgba(24,142,31,1) 49%, rgba(47,112,14,1) 100%)", mb: 4 }}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              width: 73,
            }}
            alt="The BioLens logo"
            src={theme.palette.mode === "dark" ? "/assets/logo-biolens-black-text.png" : "/assets/logo-biolens-white-text.png"}
          />
          <Box sx={{ flexGrow: 1 }}></Box>
          {!!user && (user.role == "Admin" || user.role == "PlantOwner") ? (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ pr: 1 }}>{user && user.name}</Typography>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls={open ? "action-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleMenu}
                color="inherit">
                <AccountCircle color="inherit" />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "more-button",
                }}
                color="inherit">
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={handleDarkModeSwitch}>
                  <SwitchModeButton />
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ display: "flex", justifyContent: "center", mb: 3 }} variant="dense">
        {!!user && user.role == "Admin" ? (
          <Tabs value={currentRootPath}>
            <Tab
              label={biomasses_alert ? "Biomasses*" : "Biomasses"}
              value="/masterdata"
              to="/masterdata"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label={plants_alert ? "Plants*" : "Plants"}
              value="/plants"
              to="/plants"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label="Massbalances"
              value="/massbalances"
              to="/massbalances"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label="Produced & Bought PoS"
              value="/incoming"
              to="/incoming"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="Storage" value="/storage" to="/storage" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label="Sold Substrates"
              value="/sold-substrates"
              to="/sold-substrates"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label="Available Substrates"
              value="/substrates"
              to="/substrates"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="Sold" value="/sold" to="/sold" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label={customers_alert ? "Customers*" : "Customers"}
              value="/customers"
              to="/customers"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label={"Users"} value="/users" to="/users" component={Link} sx={{ color: theme.palette.text.primary }} />
          </Tabs>
        ) : (
          ""
        )}

        {!!user && user.role == "PlantOwner" && <PlantOwnerNavbar />}
      </Toolbar>
    </>
  );
};

export default Navbar;
