import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateSupplier } from "../../hooks/suppliers";
import UpdateSupplier from "../../pages/plant/components/UpdateSupplier";
import { toast } from "react-toastify";
import { defaultTableStyle } from "../themes/tableStyle";
import { SupplierResponse } from "../../types";
import { DateFormatter } from "../../utils/DateFormatter";

type Props = {
  data: SupplierResponse[];
};

const SuppliersTable = ({ data }: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState<SupplierResponse>();
  const { mutate: updateSupplier } = useUpdateSupplier();
  const queryClient = useQueryClient();

  const supplierToUpdate = (values: SupplierResponse) => {
    console.log(values.certificationExpiryDate, typeof values.certificationExpiryDate);
    setSelectedRow(values);
    setShowEdit(true);
  };

  const deactivateSupplier = (values: SupplierResponse) => {
    setActiveSupplier(values, false);
  };

  const activateSupplier = (values: SupplierResponse) => {
    setActiveSupplier(values, true);
  };

  const setActiveSupplier = (values: SupplierResponse, active: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { certificationValid, id, certificationExpiryDate, ...requestDataObj } = values;

    const requestData = {
      ...requestDataObj,
      activated: active,
      certificationExpiryDate: certificationExpiryDate ? DateFormatter(certificationExpiryDate) : null,
    };

    updateSupplier(
      { values: requestData, supplierId: id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["suppliers"],
          });
        },
        onError: () => {
          toast.success("Error!");
        },
      },
    );
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      {showEdit && selectedRow && <UpdateSupplier data={selectedRow} showEdit={(value: boolean) => setShowEdit(value)} />}
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "owner",
            headerName: "Company name",
            minWidth: 120,
            maxWidth: 140,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "street",
            headerName: "Street",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "city",
            headerName: "City",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "postalCode",
            headerName: "Postal Code",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "country",
            headerName: "Country",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "certificationExpiryDate",
            headerName: "Certification Expiry",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => {
              return <Typography variant="caption">{params.value}</Typography>;
            },
          },
          {
            field: "activated",
            headerName: "Active Supplier",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "boolean",
            editable: false,
            renderCell: (params) => {
              return <Typography variant="caption">{params.row.activated ? "Yes" : "No"}</Typography>;
            },
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              return (
                <Box>
                  <Tooltip title="Update supplier" onClick={() => supplierToUpdate(params.row)}>
                    <IconButton color="info" disabled={showEdit}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  {params.row.activated ? (
                    <Tooltip title="Dectivate supplier" onClick={() => deactivateSupplier(params.row)}>
                      <IconButton color="primary" disabled={showEdit}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Activate supplier" onClick={() => activateSupplier(params.row)}>
                      <IconButton color="error" disabled={showEdit}>
                        <VisibilityOff />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default SuppliersTable;
