import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { toast } from "react-toastify";
import { UserResponse, UserRequest } from "../types";

const getUsers = async (): Promise<UserResponse[]> => {
  const { data } = await axios.get(`${BASE_URL}/users`);
  return data.data;
};

const addUser = async (values: UserRequest) => {
  const { data } = await axios.post(`${BASE_URL}/users`, values);
  return data.data;
};

const updateUser = async (id: string, values: UserRequest): Promise<UserResponse> => {
  const { data } = await axios.put(`${BASE_URL}/users/${id}`, values).catch((error) => Promise.reject(error));
  return data.data;
};

const deleteUser = async (id: string) => {
  const { data } = await axios.delete(`${BASE_URL}/users/${id}`);
  return data.data;
};
// -- UseQuery -- //

const usersQueryKey = "users";

export const useFetchUsers = () => {
  return useQuery<UserResponse[]>({ queryKey: [usersQueryKey], queryFn: () => getUsers() });
};

export const useAddUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newUser: UserRequest) => await addUser(newUser),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [usersQueryKey] });
      toast.success("Added User successfully!");
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, updatedUser }: { id: string; updatedUser: UserRequest }) => updateUser(id, updatedUser),
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: [usersQueryKey] });
      toast.success("User updated!");
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userToDeleteId: string) => deleteUser(userToDeleteId),
    onSuccess: (data, ..._args) => {
      queryClient.invalidateQueries({ queryKey: [usersQueryKey] });
      data.success ? toast.success("User deleted") : toast.success("User could not be deleted ");
    },
  });
};
