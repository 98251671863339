import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import InputFormText from "../../../components/form-components/InputFormText";
import { useUpdateSupplier } from "../../../hooks/suppliers";
import { toast } from "react-toastify";
import { availableCountries, getCountryISO } from "../../../utils/CountryToIso";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import { SupplierRequest, SupplierResponse } from "../../../types";
import { DateFormatter } from "../../../utils/DateFormatter";

const defaultValues: SupplierRequest = {
  owner: "",
  street: "",
  city: "",
  postalCode: "",
  country: "",
  activated: "",
  certificationExpiryDate: "",
};

type Props = {
  data: SupplierResponse;
  showEdit: (value: boolean) => void;
};

const UpdateSupplier = ({ data, showEdit }: Props) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const { mutate: updateSupplier } = useUpdateSupplier();
  const { handleSubmit, control, reset } = methods;
  const theme = useTheme();
  const queryClient = useQueryClient();

  useEffect(() => {
    const values: SupplierRequest = {
      owner: data.owner,
      street: data.street,
      city: data.city,
      postalCode: data.postalCode,
      country: getCountryISO(data.country),
      activated: data.activated,
      certificationExpiryDate: data.certificationExpiryDate ?? "",
    };
    reset(values);
  }, [data, reset]);

  const onSubmit = (values: SupplierRequest) => {
    setLoading(true);
    console.log(values.certificationExpiryDate);
    const requestData = {
      ...values,
      certificationExpiryDate: values.certificationExpiryDate ? DateFormatter(values.certificationExpiryDate) : null,
    };

    updateSupplier(
      { values: requestData, supplierId: data && data.id },
      {
        onSuccess: () => {
          toast.success("Supplier updated!");
          queryClient.invalidateQueries({
            queryKey: ["suppliers"],
          });
          showEdit(false);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">Update Supplier</Typography>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex" }}>
          <InputFormText name="owner" control={control} label="Company name" required errorText={"Company name is required"} />
          <InputFormDatePicker name="certificationExpiryDate" control={control} label="Certification Expiry" fullWidth />
          <InputFormText name="street" control={control} label="Street name" />
          <InputFormText name="postalCode" control={control} label="Postal code" />
          <InputFormText name="city" control={control} label="City" />
          <InputFormSelect name="country" control={control} label="Country" data={availableCountries} />
        </Box>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showEdit(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default UpdateSupplier;
