import { CheckCircle } from "@mui/icons-material";
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { MassBalanceResponse } from "../../types";

type MassBalanceRowTableProps = {
  data: MassBalanceResponse;
};

const MassBalanceRowTable = ({ data }: MassBalanceRowTableProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />
      <Table size="small" aria-label="massbalance rows">
        <TableHead>
          <TableRow>
            <TableCell>Biomass</TableCell>
            <TableCell>Biomass tonnage</TableCell>
            <TableCell>Biomethane potential</TableCell>
            <TableCell>Gross - m3</TableCell>
            <TableCell>Gross - kWh</TableCell>
            <TableCell>Flare</TableCell>
            <TableCell>Metane loss</TableCell>
            <TableCell>Expected Net - Kwh</TableCell>
            <TableCell>Expected Net - m3</TableCell>
            <TableCell>Self decleration</TableCell>
            <TableCell>Allocation</TableCell>
            <TableCell>Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.massBalanceRows.map((massbalanceRow) => (
              <TableRow key={massbalanceRow.id}>
                <TableCell component="th" scope="row">
                  {massbalanceRow.bioMass.name}
                </TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.sumTonnage, FRONTEND_NUMBER_FORMAT, 1)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.bioMassPotential, FRONTEND_NUMBER_FORMAT, 1)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.theoreticalGrossProductionNm3, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.theoreticalGrossProductionKWh, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.flareKWh, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.methaneLossKWh, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.expectedNetProductionKWh, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>{NumberFormatter(massbalanceRow.expectedNetProductionNm3, FRONTEND_NUMBER_FORMAT, 0)}</TableCell>
                <TableCell>
                  {massbalanceRow.selfDeclaration !== "Missing" ? <CheckCircle color="success" fontSize="small" /> : ""}
                </TableCell>
                <TableCell>{massbalanceRow.allocation}</TableCell>
                <TableCell>{massbalanceRow.country}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MassBalanceRowTable;
