import { Box, Button, CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useAddNewSupplier } from "../../../hooks/suppliers";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import InputFormText from "../../../components/form-components/InputFormText";
import { SupplierRequest } from "../../../types";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { availableCountries } from "../../../utils/CountryToIso";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import { DateFormatter } from "../../../utils/DateFormatter";

const defaultValues: SupplierRequest = {
  owner: "",
  street: "",
  city: "",
  postalCode: "",
  country: "",
  certificationExpiryDate: new Date().toDateString(),
};

type Props = {
  plantId: string;
  showCreate: (value: boolean) => void;
};

const CreateSupplier = ({ showCreate, plantId }: Props) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control } = methods;
  const { mutate: addSupplier } = useAddNewSupplier(plantId);
  const queryClient = useQueryClient();

  const onSubmit = async (values: SupplierRequest) => {
    setLoading(true);
    const requestData = {
      ...values,
      certificationExpiryDate: values.certificationExpiryDate ? DateFormatter(values.certificationExpiryDate) : null,
    };

    addSupplier(requestData, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["suppliers"] });
        toast.success("Added Supplier successfully");
        showCreate(false);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">New supplier</Typography>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex" }}>
          <InputFormText name="owner" control={control} label="Company name" required errorText={"Company name is required"} />
          <InputFormDatePicker name="certificationExpiryDate" control={control} label="Certification Expiry" fullWidth />
        </Box>
        <Box sx={{ display: "flex" }}>
          <InputFormText name="street" control={control} label="Street name" />
          <InputFormText name="postalCode" control={control} label="Postal code" />
          <InputFormText name="city" control={control} label="City" />
        </Box>
        <Box sx={{ display: "flex" }}>
          <InputFormSelect name="country" control={control} label="Country" data={availableCountries} />
        </Box>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showCreate(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default CreateSupplier;
