import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Divider, Grid, Typography, CircularProgress, Avatar, useTheme, Alert } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import InputFormFile from "../../../components/form-components/InputFormFile";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import { useSubstrateMonthUploadData } from "../../../hooks/substrates";
import { DateFormatter } from "../../../utils/DateFormatter";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const defaultValues = {
  substrateFile: null,
};

type Props = {
  showCreate: (value: boolean) => void;
  plantId: string;
};

const ImportSubstrateData = ({ showCreate, plantId }: Props) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: addSubstrateList } = useSubstrateMonthUploadData(plantId);

  const methods = useForm({
    defaultValues: {
      substrateListMonth: location?.state?.selectedDate || new Date(),
      ...defaultValues,
    },
  });
  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset();
  }, [plantId, reset]);

  const onSubmit: SubmitHandler<{ substrateFile: File | null; substrateListMonth: string }> = async (values) => {
    if (!values.substrateFile) {
      return;
    }

    setLoading(true);
    const substrateListMonthDate = new Date(values.substrateListMonth);
    const producedAt = DateFormatter(new Date(substrateListMonthDate.getFullYear(), substrateListMonthDate.getMonth(), 1).toString());

    const requestData = {
      producedAt: producedAt,
      substrateFile: values.substrateFile,
    };

    addSubstrateList(requestData, {
      onError: (error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          setErrorMessage(error?.response?.data.detail);
        }
      },
      onSuccess: () => {
        setLoading(false);
        toast.success("Uploaded successfully");
        queryClient.invalidateQueries({
          queryKey: ["substrateLists", plantId],
        });
        reset();
        showCreate(false);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: 2, marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ pl: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 30, height: 30 }}>1</Avatar>
                  <Typography variant="body1" sx={{ pl: 2 }}>
                    Add a substrate list
                  </Typography>
                </Box>
                <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
                <Box sx={{ mx: 2 }}>
                  <InputFormFile
                    name="substrateFile"
                    control={control}
                    label="Upload"
                    required={true}
                    errorText="Substrate file is required"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Box sx={{ pl: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 30, height: 30 }}>2</Avatar>
                  <Typography variant="body1" sx={{ pl: 2 }}>
                    Select month and year for substrate list
                  </Typography>
                </Box>
                <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
                <InputFormDatePicker
                  name="substrateListMonth"
                  control={control}
                  label="Select month"
                  required={true}
                  format="MM/yyyy"
                  errorText="Month and year is required"
                  formatViews={["month", "year"]}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
          <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button variant="text" color="warning" onClick={() => showCreate(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="success" type="submit">
                  Save
                </Button>
              </>
            )}
          </Box>
          {!!errorMessage && (
            <Box>
              <Alert severity="error">
                <Typography variant="body2">{errorMessage}</Typography>
              </Alert>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};

export default ImportSubstrateData;
