import { CustomerResponse } from "./CustomerType";

export interface PosResponse {
  id: string;
  uniqueNumber: string;
  productionPeriodStart: Date;
  productionPeriodEnd: Date;
  issueDate: Date;
  inBathtub: boolean;
  supplier: CustomerResponse;
  recipient: CustomerResponse;
  product: string;
  co2EquivalentType: number;
  plantId: string;
  plantName: string;
  subsidy: boolean;
  crossCompliance: string;
  landUse: boolean;
  massBalanceId: string;
  contractNumber: string;
  incomingPosData: IncomingPosDataResponse[];
}

export interface IncomingPosDataResponse {
  feedstockBioMassTypeId: string;
  feedstockBioMassTypeName: string;
  feedstockBioMassTypeCategory: number;
  feedstockCountry: string;
  producedAt: Date;
  efs: number;
  eec: number;
  el: number;
  ep: number;
  etd: number;
  eu: number;
  esca: number;
  eccs: number;
  eccr: number;
  quantityKwh: number;
  selfDeclaration: number;
}

export const BioMassTypeCategory = {
  "-1": "Uncategorized",
  "0": "Manure",
  "1": "Biowaste",
  "2": "Crops",
};

export type BioMassTypeCategoryType = keyof typeof BioMassTypeCategory;
