import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Divider, IconButton, Modal, TextField, Typography, useTheme } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useAddOutgoingDanishPos, useAddOutgoingSwedishPos } from "../../../hooks/outgoingPos";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../../utils/NumberFormatter";
import { useFetchCustomers } from "../../../hooks/customer";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { DateFormatter, FRONTEND_SHORT_MONTH_FORMAT } from "../../../utils/DateFormatter";
import InputFormText from "../../../components/form-components/InputFormText";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { BathTubCartItemResponse, Calculation } from "../../../types";

type CreateOutgoingPos = {
  contractNumber: string;
  comment: string;
  sellingCompany: "dk" | "se";
  recipientId: string;
};

const defaultValues: CreateOutgoingPos = {
  contractNumber: "",
  comment: "",
  sellingCompany: "dk",
  recipientId: "",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type Props = {
  onOpen: boolean;
  onClose: (selectedRows: BathTubCartItemResponse[], value: boolean) => void;
  data: BathTubCartItemResponse[];
  totalAmount: Calculation | null;
};

const SummaryModal = ({ onOpen, onClose, data, totalAmount }: Props) => {
  const [totaltAmount, setTotalAmount] = useState<Calculation | null>(null);
  const [selectedDataItems, setSelectedDataItems] = useState<BathTubCartItemResponse[]>([]);
  const methods = useForm({ defaultValues: defaultValues, mode: "onChange" });
  const { handleSubmit, reset, formState, control } = methods;
  const theme = useTheme();
  const { data: customerData } = useFetchCustomers();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addOutgoingDanishPos = useAddOutgoingDanishPos();
  const addOutgoingSwedishPos = useAddOutgoingSwedishPos();

  useEffect(() => {
    if (data) {
      setSelectedDataItems(data);
      setTotalAmount(totalAmount);
    }
  }, [data, totalAmount]);

  useEffect(() => {
    // Calculate new total amounts
    if (selectedDataItems.length === 0) setTotalAmount(null);
    else {
      const totalMWh = selectedDataItems.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
      const totalCo2 = selectedDataItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount * currentValue.sku.gramCo2EqPerMegaJoule,
        0,
      );

      const co2Score = totalCo2 / totalMWh;
      const totalAmount: Calculation = {
        higherValue: totalMWh,
        co2scoreValue: Number(co2Score.toFixed(2)),
        co2savings: Number(((1 - co2Score / 94) * 100).toFixed(0)),
        lowerValue: Number(((11.057 / 12.157) * totalMWh).toFixed(0)),
      };

      setTotalAmount(totalAmount);
    }
  }, [selectedDataItems]);

  const onSubmit = (values: CreateOutgoingPos) => {
    const skuList = selectedDataItems.map((item) => ({ id: item.sku.id, kwh: item.amount * 1000 }));
    const customer = customerData?.find((customer) => customer.id === values.recipientId);

    if (!customer) {
      toast.error("Could not find the customer");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...recipient } = customer;

    const request = {
      uniqueNumber: "",
      contractNumber: values.contractNumber,
      comment: values.comment,
      recipient: recipient,
      substrates: skuList,
    };

    if (values.sellingCompany == "se") {
      addOutgoingSwedishPos.mutate(request, {
        onError: (error) => {
          console.log(error);
        },
        onSuccess: () => {
          toast.success("Outgoing PoS created from Swedish company!");
          onCloseModal();
          queryClient.invalidateQueries({ queryKey: ["outgoing-pos"] });
          queryClient.invalidateQueries({ queryKey: ["bathtub"] });
          navigate("/sold");
        },
      });
    } else {
      addOutgoingDanishPos.mutate(request, {
        onError: (error) => {
          console.log(error);
        },
        onSuccess: () => {
          toast.success("Outgoing PoS created!");
          onCloseModal();
          queryClient.invalidateQueries({ queryKey: ["outgoing-pos"] });
          queryClient.invalidateQueries({ queryKey: ["bathtub"] });
          navigate("/sold");
        },
      });
    }
  };

  const onCloseModal = () => {
    reset();
    onClose(selectedDataItems, true);
  };

  const editRowAmount = (params: BathTubCartItemResponse, newAmount: number) => {
    const selectedRowsWithoutCurrent = selectedDataItems.filter((r) => r.sku.id !== params.sku.id);
    if (newAmount === 0) {
      setSelectedDataItems(selectedRowsWithoutCurrent);
    } else {
      const updatedRow: BathTubCartItemResponse = {
        sku: params.sku,
        amount: newAmount,
      };
      setSelectedDataItems([...selectedRowsWithoutCurrent, updatedRow]);
    }
  };

  const getInputValueMWh = (row: BathTubCartItemResponse) => {
    const selected = selectedDataItems.find((r) => r.sku.id === row.sku.id);
    if (selected) {
      return selected.amount;
    }
    return 0;
  };

  const handleCo2Score = (params: GridRenderCellParams<BathTubCartItemResponse>) => {
    let caption = "g CO2-eq/MJ";
    let color = theme.palette.error.light;
    let value = +params.row.sku.gramCo2EqPerMegaJoule.toFixed(1);

    if (value < 0) color = theme.palette.primary.main;
    if (value == 0) color = theme.palette.warning.light;

    if (params.row.sku.selfDeclaration == 0) {
      caption = "";
      value = NaN;
      color = theme.palette.error.dark;
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" color={color} sx={{ p: 0.8 }}>
          <strong>{isNaN(value) ? "N/A" : NumberFormatter(value, FRONTEND_NUMBER_FORMAT, 1)}</strong>
        </Typography>
        <Typography variant="caption">{caption}</Typography>
      </Box>
    );
  };

  const renderMWhColumn = (params: GridRenderCellParams) => {
    return (
      <Box width="100%" sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          value={getInputValueMWh(params.row)}
          variant="standard"
          size="small"
          type="number"
          sx={{
            backgroundColor: "rgba(38, 153, 70, 0.05)",
            borderRadius: 1,
            fontSize: "5px",
            paddingLeft: 1,
            paddingRight: 1,
            marginRight: 1,
            minWidth: 64,
          }}
          onChange={(e) => {
            const min = 0;
            const max = Math.trunc(params.row.sku.availableKWh / 1000);
            let value = parseInt(e.target.value, 10);

            if (value > max) value = max;
            if (value < min) value = min;

            editRowAmount(params.row, value);
          }}
        />
        <Typography variant="caption" className="hover">
          <strong> of {Math.trunc(params.row.sku.availableKWh / 1000)}</strong>
        </Typography>
      </Box>
    );
  };

  return (
    <Modal open={onOpen} onClose={onCloseModal} aria-labelledby="summary-modal" aria-describedby="summary-modal">
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
            Summary
          </Typography>
          <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
          <Box sx={{ height: 420, maxWidth: "100%" }}>
            <DataGrid
              rows={data}
              getRowId={(row) => row.sku.id}
              columns={[
                {
                  field: "bioMassName",
                  headerName: "Substrate",
                  flex: 1,
                  editable: false,
                  renderCell: (params) => {
                    return (
                      <Typography variant="body1">
                        <strong>{params.row.sku.bioMassName}</strong>
                      </Typography>
                    );
                  },
                },
                {
                  field: "plantName",
                  headerName: "Plant",
                  flex: 1,
                  editable: false,
                  renderCell: (params) => <Typography variant="caption">{params.row.sku.plantName}</Typography>,
                },
                {
                  field: "producedAt",
                  headerName: "Produced",
                  type: "date",
                  flex: 0.5,
                  editable: false,
                  renderCell: (params) => (
                    <Box>
                      <Typography variant="caption">{DateFormatter(params.row.sku.producedAt, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
                    </Box>
                  ),
                },
                {
                  field: "expiresAt",
                  headerName: "Expires",
                  type: "date",
                  flex: 0.5,
                  editable: false,
                  renderCell: (params) => (
                    <Box>
                      <Typography variant="caption">{DateFormatter(params.row.sku.expiresAt, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
                    </Box>
                  ),
                },
                {
                  field: "country",
                  headerName: "Country",
                  flex: 0.5,
                  editable: false,
                  renderCell: (params) => <Typography variant="caption">{params.row.sku.country}</Typography>,
                },
                {
                  field: "gramCo2EqPerMegaJoule",
                  headerName: "CI-Score",
                  type: "number",
                  flex: 0.5,
                  editable: false,
                  align: "left",
                  headerAlign: "left",
                  renderCell: handleCo2Score,
                },
                {
                  field: "availableKWh",
                  headerName: "MWh",
                  type: "number",
                  flex: 1,
                  editable: false,
                  align: "left",
                  headerAlign: "left",
                  valueGetter: (params) => params.row.amount / 1000,
                  renderCell: renderMWhColumn,
                },
                {
                  field: "actions",
                  headerName: "",
                  flex: 0.2,
                  align: "right",
                  filterable: false,
                  disableColumnMenu: true,
                  editable: false,
                  sortable: false,
                  renderCell: (params) => (
                    <Box>
                      <IconButton aria-label="delete-row" color="warning" onClick={() => editRowAmount(params.row, 0)}>
                        <Clear />
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 8 } } }}
              density="compact"
              hideFooterSelectedRowCount
              isRowSelectable={() => false}
              getRowClassName={(params) => {
                const rowIsAdded = selectedDataItems.find((item) => params.id === item.sku.id);
                return rowIsAdded?.sku.id === params.row.sku.id ? `RowWithUserAmountAdded` : "";
              }}
              sx={{
                [`& .MuiDataGrid-cell `]: {
                  borderBottom: "none",
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .Mui-selected": {
                  background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
                },
                [`& .MuiDataGrid-row `]: {
                  background: theme.palette.mode === "dark" ? "#333333" : "#fff",
                  my: 0.3,
                  borderRadius: 1,
                  maxWidth: "100%",
                  "&:hover": {
                    background: theme.palette.mode === "dark" ? "#333333" : "#fff",
                  },
                },
                [`&.MuiDataGrid-root `]: {
                  border: "none",
                },
                [`& .MuiDataGrid-columnSeparator `]: {
                  display: "none",
                },
                "& .RowWithUserAmountAdded": {
                  background: "rgba(38, 153, 70, 0.1)",
                  my: 0.3,
                  borderRadius: 1,
                  maxWidth: "100%",
                  "&:hover": {
                    background: "rgba(38, 153, 70, 0.1)",
                  },
                },
              }}
            />
          </Box>
          {data.some((r) => r.sku.selfDeclaration > 0) && data.some((r) => r.sku.selfDeclaration == 0) ? (
            <Box paddingBottom={5}>
              <Alert severity="warning">
                <Typography variant="body2">Mixed selfdeclaration will result in a non-RED certified PoS.</Typography>
              </Alert>
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              Total:
            </Typography>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {NumberFormatter(totaltAmount?.co2scoreValue, FRONTEND_NUMBER_FORMAT, 2)} CO2-eq g/MJ
            </Typography>
            <Typography variant="body1">{totaltAmount?.higherValue} MWh</Typography>
          </Box>
          <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
          <Box sx={{ display: "flex", gap: 2, mt: 3, width: "100%" }}>
            <InputFormText name="contractNumber" control={control} label="Contract number" required />
            <InputFormText name="comment" control={control} label="Comment" required />
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 3, width: "100%" }}>
            <Box sx={{ flex: 1 }}>
              <Typography id="recipient-title" variant="body1" sx={{ mt: 3 }}>
                <strong>Sell from</strong>
              </Typography>
              <Divider sx={{ mt: 1, mb: 2, bgcolor: theme.palette.primary.main }} />
              <Box sx={{ p: 2 }}>
                <InputFormSelect
                  name="sellingCompany"
                  control={control}
                  label="Company"
                  unsetMargin={true}
                  required
                  data={[
                    { name: "Biogem ApS", id: "dk" },
                    { name: "Biogem AB", id: "se" },
                  ]}
                />
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography id="recipient-title" variant="body1" sx={{ mt: 3 }}>
                <strong> Biomethane Recipient</strong>
              </Typography>
              <Divider sx={{ mt: 1, mb: 2, bgcolor: theme.palette.primary.main }} />
              <Box sx={{ p: 2 }}>
                <InputFormSelect
                  name="recipientId"
                  control={control}
                  label="Select Customer"
                  required
                  unsetMargin={true}
                  data={customerData?.map((customer) => ({ id: customer.id, name: customer.name }))}
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
            <>
              <Button variant="text" color="warning" onClick={() => onCloseModal()}>
                Cancel
              </Button>
              <Button variant="contained" color="success" type="submit" disabled={!formState.isValid}>
                Create
              </Button>
            </>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default SummaryModal;
