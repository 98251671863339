import axios from "../services/axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import {
  DataResponse,
  SubstrateMonthListResponse,
  SubstrateMonthSummaryResponse,
  SubstrateListDayResponse,
  SubstrateListResponse,
  UpdateSubstrateListDayRequest,
  SubstrateListRequest,
  UpdateSubstrateListRequest,
  BulkSubstrateRequest,
} from "../types";

export const useSubstrateMonthUploadData = (plantId: string) => {
  return useMutation({
    mutationKey: ["substrateLists", plantId],
    mutationFn: async (data: { producedAt: string; substrateFile: File }) => {
      const formData = new FormData();
      formData.append("File", data.substrateFile);
      const { status } = await axios.post(`${BASE_URL}/substratelist/months/upload`, formData, {
        params: {
          producedAt: data.producedAt,
          plantId: plantId,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (status) {
        return true;
      } else {
        return false;
      }
    },
  });
};

export const useFetchSubstrateMonthLists = (plantId: string) => {
  return useQuery({
    queryKey: ["substrateLists", plantId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateMonthListResponse[]>>(`${BASE_URL}/substratelist/months`, {
        params: {
          plantId,
          isLocked: true,
        },
      });
      return data.data;
    },
  });
};

export const useCreateSubstrateMonth = (plantId: string, producedAt: string) => {
  return useQuery({
    queryKey: ["substrateLists", plantId, producedAt],
    queryFn: async () => {
      const { data } = await axios.post<DataResponse<SubstrateMonthListResponse>>(`${BASE_URL}/substratelist/months`, {
        plantId,
        producedAt,
      });
      return data.data;
    },
  });
};

export const useExportSubstrateMonth = () => {
  return useMutation({
    mutationKey: ["exportSubstrateMonth"],
    mutationFn: async (substrateMonthId: string) => {
      const url = `${BASE_URL}/substratelist/months/${substrateMonthId}/export`;
      const { data } = await axios.get(url);
      const link = document.createElement("a");
      link.href = data.data;
      link.download = `${substrateMonthId}.csv`;
      link.click();
      return true;
    },
  });
};

export const useLockSubstrateMonth = (plantId: string, producedAt: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["lockSubstrateMonth"],
    mutationFn: async (substrateMonthId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/months/${substrateMonthId}/lock`);
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", plantId, producedAt],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useFetchSubstratDays = (monthId: string) => {
  return useQuery({
    queryKey: ["substrateDaysLists", monthId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListDayResponse[]>>(`${BASE_URL}/substratelist/days`, {
        params: {
          monthId,
        },
      });
      return data.data;
    },
  });
};

export const useFetchSubstrates = (daylistId: string) => {
  return useQuery({
    queryKey: ["substrates", daylistId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListResponse[]>>(`${BASE_URL}/substratelist/days/${daylistId}/substrates`);
      return data.data;
    },
  });
};

export const useFetchMonthlySummaries = (plantId: string) => {
  return useQuery({
    queryKey: ["substrates", plantId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateMonthSummaryResponse[]>>(
        `${BASE_URL}/substratelist/months/summary/${plantId}`,
      );
      return data.data;
    },
  });
};

export const useFetchMonthlySummary = (monthId: string) => {
  return useQuery({
    queryKey: ["substrates", monthId],
    queryFn: async () => {
      try {
        const { data } = await axios.get<DataResponse<SubstrateMonthSummaryResponse>>(
          `${BASE_URL}/substratelist/months/${monthId}/summary`,
        );
        return data.data;
      } catch (error) {
        // Catch error and don't show it in frontend
      }
    },
  });
};

// This should be a Mutation
export const useCreateSubstrateDay = (plantId: string, date: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["createSubstrateDaysLists", plantId, date],
    queryFn: async () => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/days`, {
        plantId,
        day: date,
      });

      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1;
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists", plantId, year, month],
      });
      return data.data;
    },
  });
};

export const useUpdateSubstrateDaylist = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["substrateDaysLists", daylistId],
    mutationFn: async (payload: UpdateSubstrateListDayRequest) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylistId}`, payload);
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useLockSubstrateDaylist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (daylistId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylistId}/lock`);
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useUnLockSubstrateDaylist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (daylistId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylistId}/unlock`);

      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useUnlockSubstrateMonth = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateMonthId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/months/${substrateMonthId}/unlock`);
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", plantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useDeleteSubstrateMonthList = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateListId: string) => {
      const { data } = await axios.delete(`${BASE_URL}/substratelist/months/${substrateListId}`);
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", plantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useDeleteSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["substrateLists", daylistId],
    mutationFn: async (substrateId: string) => {
      const { data } = await axios.delete(`${BASE_URL}/substratelist/substrates/${substrateId}`);

      queryClient.invalidateQueries({
        queryKey: ["substrates", daylistId],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useCreateSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["substrateLists", daylistId],
    mutationFn: async (payload: SubstrateListRequest) => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/days/${daylistId}/substrates`, payload);

      queryClient.invalidateQueries({
        queryKey: ["substrates", daylistId],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useUpdateSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["substrateLists", daylistId],
    mutationFn: async (payload: UpdateSubstrateListRequest) => {
      const { id, ...substrateData } = payload;
      const { data } = await axios.put(`${BASE_URL}/substratelist/substrates/${id}`, substrateData);
      queryClient.invalidateQueries({
        queryKey: ["substrates", daylistId],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      return data.data;
    },
  });
};

export const useCreateBulkSubstrate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: BulkSubstrateRequest) => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/bulk`, payload);
      queryClient.invalidateQueries({
        queryKey: ["substrates"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      return data.data;
    },
  });
};
