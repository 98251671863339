import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline, CheckCircleOutline, CloudDownload, Delete, PictureAsPdf } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarContainerProps,
  GridToolbarExport,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useAddToStorage, useDeletePoS } from "../../hooks/incomingPos";
import { DateFormatter, FRONTEND_DATE_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { PosResponse } from "../../types/index";
import { getTotalSumInKWh } from "../../utils/Calculations";
import { onDownloadFile } from "../../utils/DownloadFiles";

interface IncomingPosTableProps {
  data: PosResponse[];
  isAdmin?: boolean;
}

const IncomingPosTable = ({ data, isAdmin }: IncomingPosTableProps) => {
  const [showOnlyAdded, setShowOnlyAdded] = useState<boolean | null>(null);
  const [filteredData, setFilteredData] = useState<PosResponse[]>([]);
  
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addPosToStorage = useAddToStorage();
  const deletePoS = useDeletePoS();

  const handleDeletePoS = (id: string) => {
    deletePoS.mutate(id, {
      onSuccess: () => {
        toast.success("Delted PoS");
        queryClient.invalidateQueries({ queryKey: ["pos"] });
        navigate("/incoming");
      },
    });
  };

  const handleAddToStorage = (id: string) => {
    addPosToStorage.mutate(id, {
      onSuccess: () => {
        toast.success("Added Pos to storage!");
        queryClient.invalidateQueries({ queryKey: ["pos"] });
        queryClient.invalidateQueries({ queryKey: ["bathtub"] });
        navigate("/storage");
      },
    });
  };

  const showFilteredData = () => {
    if (showOnlyAdded) {
      const result = data.filter(({ inBathtub }) => inBathtub);
      setFilteredData(result);
    }
    if (!showOnlyAdded) {
      const result = data.filter(({ inBathtub }) => !inBathtub);
      setFilteredData(result);
    }
  };

  function CustomToolbar(props: GridToolbarContainerProps) {
    return (
      <Box sx={{ display: "flex", m: 1 }}>
        <GridToolbarContainer sx={{ flexGrow: 1 }} {...props}>
          <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarExport />
        </GridToolbarContainer>
        {isAdmin && (
          <Box p={1}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setShowOnlyAdded(!showOnlyAdded);
                showFilteredData();
              }}
              sx={{ mr: 1 }}>
              {showOnlyAdded ? "Show only added" : "Show only non added"}
            </Button>
            <Button variant="outlined" color="info" onClick={() => setShowOnlyAdded(null)} size="small" disabled={showOnlyAdded === null}>
              Show all
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        onRowClick={(params: GridRowParams) => navigate(`/incoming/${params.row.id}`)}
        rows={showOnlyAdded !== null ? filteredData : data}
        columns={[
          {
            field: "uniqueNumber",
            headerName: "PoS Number",
            flex: 1,
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{params.value}</strong>
              </Typography>
            ),
          },
          {
            field: "incomingPosQuantityKwh",
            headerName: "Total amount ",
            type: "number",
            flex: 0.75,
            editable: false,
            valueGetter: (params) => {
              /*
              This is only for correct number when download table as CSV.
              */
              return getTotalSumInKWh(params.row.incomingPosData);
            },
            renderCell: (params) => {
              const displayedAmount = getTotalSumInKWh(params.row.incomingPosData);
              return (
                <Box display="flex">
                  <Typography variant="caption" color={theme.palette.warning.main} sx={{ mr: 1 }}>
                    <strong>{NumberFormatter(displayedAmount, FRONTEND_NUMBER_FORMAT)} </strong>
                  </Typography>
                  <Typography variant="caption">MWh</Typography>
                </Box>
              );
            },
          },
          {
            field: "plantName",
            headerName: "Plant",
            flex: 0.75,
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "issueDate",
            headerName: "Date of issuance",
            type: "string",
            flex: 0.5,
            editable: false,
            renderCell: (params) => (
              <Box>
                <Typography variant="caption">{DateFormatter(params.value, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            ),
          },
          {
            field: "productionPeriodStart",
            headerName: "Period Start",
            type: "string",
            flex: 0.5,
            editable: false,
            renderCell: (params) => (
              <Box>
                <Typography variant="caption">{DateFormatter(params.value, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            ),
          },
          {
            field: "productionPeriodEnd",
            headerName: "Period End",
            type: "string",
            flex: 0.5,
            editable: false,
            renderCell: (params) => (
              <Box>
                <Typography variant="caption">{DateFormatter(params.value, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            ),
          },
          {
            field: "contractNumber",
            headerName: "Contract number",
            flex: 0.75,
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
          },
          {
            field: "massBalanceId",
            headerName: "Type",
            flex: 0.5,
            editable: false,
            align: "left",
            valueGetter: (params) => {
              return params.value ? "Produced" : "Bought";
            },
          },
          {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            cellClassName: "actions",
            getActions: (params: GridRowParams<PosResponse>) => {
              const displayAddToStorageArray = [
                <GridActionsCellItem
                  key={`add-to-storage`}
                  icon={<AddCircleOutline color="primary" />}
                  label={"Add PoS to Storage"}
                  showInMenu
                  onClick={() => handleAddToStorage(params.row.id)}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={`delete-pos`}
                  icon={<Delete color="primary" />}
                  label={"Delete PoS"}
                  showInMenu
                  onClick={() => handleDeletePoS(params.row.id)}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  hidden={true}
                  key={2}
                  label="Download as Excel"
                  showInMenu
                  icon={<CloudDownload color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "xlsx",
                      dataType: "incomingpos",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={3}
                  label="Download as PDF"
                  showInMenu
                  icon={<PictureAsPdf color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "pdf",
                      dataType: "incomingpos",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
              ];

              const displayAlreadyAddedArray = [
                <GridActionsCellItem
                  key={`already-added`}
                  icon={<CheckCircleOutline color="primary" />}
                  label={"Already added to storage"}
                  showInMenu
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
              ];

              const downloadPosAlternativesPoS = [
                <GridActionsCellItem
                  hidden={true}
                  key={`download-excel`}
                  label="Download as Excel"
                  showInMenu
                  icon={<CloudDownload color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "xlsx",
                      dataType: "incomingpos",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={`download-pdf`}
                  label="Download as PDF"
                  showInMenu
                  icon={<PictureAsPdf color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "pdf",
                      dataType: "incomingpos",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
              ];

              if (!isAdmin) {
                return downloadPosAlternativesPoS;
              }

              return params.row.inBathtub
                ? [...displayAlreadyAddedArray, ...downloadPosAlternativesPoS]
                : [...displayAddToStorageArray, ...downloadPosAlternativesPoS];
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        hideFooterSelectedRowCount
        isRowSelectable={() => false}
        getRowClassName={(params) => {
          return isAdmin && params.row.inBathtub ? "AddedToBath" : "NotAddedToBath";
        }}
        sx={{
          [`& .AddedToBath `]: {
            background:
              theme.palette.mode === "dark" ? `${theme.palette.primary.dark} !important` : `${theme.palette.primary.light} !important`,
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#2b7c3d !important" : "#5fb878 !important",
            },
          },
          border: 0,
          [`& .MuiDataGrid-cell `]: {
            borderBottom: "none",
            "&:focus-within": {
              outline: "none",
            },
          },
          [`& .Mui-selected `]: {
            background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
          },
          [`& .MuiDataGrid-row `]: {
            background: theme.palette.mode === "dark" ? "#333333" : "none",
            my: 0.3,
            borderRadius: 1,
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
            },
          },
          [`& .MuiDataGrid-columnSeparator `]: {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default IncomingPosTable;
