import axios from "../services/axios";
import { useMutation, useQuery } from '@tanstack/react-query';
import { BASE_URL } from "../services/base";
import { DataResponse, MassBalanceResponse, SubstrateListResponse } from "../types";

export const useFetchMassbalances = () => {
  return useQuery({
    queryKey: ["massbalances"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse[]>>(`${BASE_URL}/massbalances`);
      return data.data;
    },
  });
};

export const useFetchMassbalancesByPlant = (plantId: string) => {
  return useQuery({
    queryKey: ["massbalances", "plant", plantId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse[]>>(`${BASE_URL}/massbalances?plantId=${plantId}`);
      return data.data;
    },
  });
};

export const useAddIncomingPos = (id: string) => {
  return useMutation({
    mutationKey: ["pos", id],
    mutationFn: async (subsidy: boolean) => {
      const posData = {
        subsidy: subsidy,
      };
      const { data } = await axios.post(`${BASE_URL}/massbalances/${id}/pos`, posData);
      return data.data;
    },
  });
};

export const useFetchMassbalanceById = (id: string) => {
  return useQuery({
    queryKey: ["massbalances", id],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse>>(`${BASE_URL}/massbalances/${id}`);
      return data.data;
    },
  });
};

export const useFetchSubstrateList = (id: string) => {
  return useQuery({
    queryKey: ["substrate", id],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListResponse[]>>(
        `${BASE_URL}/massbalances/${id}/substrates?limit=1000&offset=0`,
      );
      return data.data;
    },
  });
};

export const useDeleteMassbalance = (id: string) => {
  return useMutation({
    mutationKey: ["massbalances"],
    mutationFn: async () => {
      const { data } = await axios.delete(`${BASE_URL}/massbalances/${id}`);
      return data.data;
    },
  });
};
