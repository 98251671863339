import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { CreateProductionDataRequest, DataResponse, PlantRequest, PlantResponse, ProductionDataResponse } from "../types";

export const useFetchPlants = () => {
  return useQuery({
    queryKey: ["plants"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<PlantResponse[]>>(`${BASE_URL}/plants`);
      return data.data;
    },
  });
};

export const useAddPlant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values: PlantRequest) => {
      const { data } = await axios.post(`${BASE_URL}/plants`, values);
      return data.data;
    },
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: ["plants"] });
    },
  });
};

export const useUpdatePlant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ plantId, plantData }: { plantId: string; plantData: PlantRequest }) => {
      const { data } = await axios.put(`${BASE_URL}/plants/${plantId}`, plantData);
      return data.data;
    },
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: ["plants"] });
    },
  });
};

export const useAddPlantMassbalance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { plantId: string; producedAt: string }) => {
      const { plantId, ...value } = data;
      return await axios.post(`${BASE_URL}/plants/${plantId}/massbalances`, value);
    },
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: ["massbalances"] });
      queryClient.invalidateQueries({ queryKey: ["potentials"] });
    },
  });
};

export const useFetchMonthlyProduction = (plantId: string) => {
  return useQuery({
    queryKey: ["monthlyProductions", plantId],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<ProductionDataResponse[]>>(`${BASE_URL}/plants/${plantId}/productions`);
      return data.data;
    },
  });
};

export const useAddMonthlyProduction = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ plantId, values }: { plantId: string; values: CreateProductionDataRequest }) => {
      const response = await axios.post(`${BASE_URL}/plants/${plantId}/productions`, values);
      return response.data;
    },
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: ["monthlyProductions", plantId] });
    },
  });
};

export const useDeleteMonthlyProduction = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (productionId: string) => {
      const response = await axios.delete(`${BASE_URL}/plants/productions/${productionId}`);
      return response.data;
    },
    onSuccess: (..._args) => {
      queryClient.invalidateQueries({ queryKey: ["monthlyProductions", plantId] });
    },
  });
};
